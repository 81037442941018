<template>
  <div class="home">
    <!--  BANNER  -->
    <a-row>
      <a-col :lg="10" span="24">
        <div class="home_text" style="padding-left:42px; padding-right: 22px">
          <div class="title">
            <h2>快速搭建独立站<br>助力卖家<span class="org">品牌出海</span></h2>
            <p>
              专业建站系统，资深专家扶持，<br>全方位赋能助力您的企业实现销售指数级增长。
            </p>
            <a-button round style="width: 184px;height: 50px;font-weight: bolder;border-radius: 50px;font-size: 18px" @click="$register()">
              立刻免费试用
            </a-button>
          </div>
        </div>
      </a-col>
      <a-col :lg="14" span="24" class="banner-col">
        <video poster="https://asudh.oss-cn-shenzhen.aliyuncs.com/home_video.jpg"  id="homeVideo" autoplay class="home_video" loop muted playsinline preload="auto">
          <source src="https://asudh.oss-cn-shenzhen.aliyuncs.com/home.mp4" type="video/mp4">
        </video>
      </a-col>
    </a-row>
    <ArticleTpl :reverse="true" :data="tplData1" style="margin-top: 80px">
      <template #title>
        <h3 class="tplTitle">
          海量行业模板免费使用<br>
          一键使用、一键切换
        </h3>
      </template>
      <template v-slot:ad>
        <a class="tplAD" @click="$register()">Style navigation</a>
      </template>
    </ArticleTpl>
    <a-row type="flex" justify="center" style="background:#F8F8F8;margin-top: 38px;padding: 50px 0;">
      <a-col :md="18" span="22">
        <carousel
          class="home_carousel"
          :items="5"
          :autoplay="true"
          :smart-speed="500"
          :nav="false"
        >
          <div v-for="(item,index) in tplModel" :key="'tplModel'+ index" class="tplBox">
            <img class="modelImg" :src="item">
          </div>
        </carousel>
      </a-col>
    </a-row>
    <ArticleTitle style="margin-top: 80px">
      全方位各类渠道支持
    </ArticleTitle>
    <ArticleTpl :data="tplData2" style="margin-top: 60px">
      <template #title>
        <h3 class="tplTitle">
          独立站<span>营销</span>方式
          <br>
          帮您对接全网解决方案
        </h3>
      </template>
      <template v-slot:ad>
        <a class="tplAD" @click="$register()">Sign up experience</a>
      </template>
    </ArticleTpl>
    <ArticleTpl :reverse="true" :data="tplData3" :map-bg="true" style="margin-top: 80px">
      <template #title>
        <h3 class="tplTitle">
          <span>千万级</span>SKU供应链
          <br>
          提供选品商城，一键上架
        </h3>
      </template>
      <template v-slot:ad>
        <a class="tplAD" @click="$register()">Sign up experience</a>
      </template>
    </ArticleTpl>
    <ArticleTpl :data="tplData4" style="margin-top: 80px">
      <template #title>
        <h3 class="tplTitle">
          <span>本地化</span>特色功能
          <br>
          应对购物习惯差异
        </h3>
      </template>
      <template v-slot:ad>
        <a class="tplAD" @click="$register()">Sign up experience</a>
      </template>
    </ArticleTpl>
    <CustomCarousel />
    <ArticleTitle style="margin-top: 140px">
      国际物流+海外仓
    </ArticleTitle>
    <p style="margin-top: 33px;font-size: 24px;font-family:'PingFang SC',serif;">
      一线国际物流战略合作，海外仓部署覆盖全球，打造专业的物流解决方案，即省钱又省时！
    </p>
    <video id="video" src="/images/map.mp4" muted playsinline autoplay loop style="max-width: 1440px;width: 100%;height: auto" />
    <section class="home-module home-banner" style="margin-top: 0px;margin-bottom: 36px">
      <div class="home-module-word ">
        <h3 class="title-word">
          <div class="pc">注册入驻享<span style="font-size: 6.5rem;">N</span>大权益</div>
          <div class="mobile">注册入驻<div>享<span style="font-size: 6.5rem;">N</span>大权益</div></div>
        </h3>
        <p style="margin-top: 1px;font-size: 24px;font-family:'PingFang SC';color: #fff">
          轻松快速的开启跨境贸易
        </p>
      </div>
    </section>
    <function-list />
    <ArticleTitle style="margin-top: 140px">
      合作品牌
    </ArticleTitle>
    <section class="brand-section">
      <b-container>
        <carousel
          :items="5"
          :loop="true"
          :autoplay="true"
          :margin="30"
          :autoplay-hover-pause="true"
          :dots="false"
          :nav="false"
          :smart-speed="500"
          slide-by="page"
          :responsive="{
            0: {
              items: 2
            },
            480: {
              items: 2
            },
            576: {
              items: 3
            },
            768: {
              items: 3
            },
            960: {
              items: 4
            },
            1200: {
              items: 5
            }
          }"
          class="brand-slider"
        >
          <div v-for="(item,index) in brandList" :key="index" class="item">
            <img :src="item" alt="img">
          </div>
        </carousel>
      </b-container>
    </section>
    <home-footer-banner></home-footer-banner>
  </div>
</template>

<script>
// import $ from 'jquery'
import ArticleTitle from 'components/ArticleTitle'
import carousel from 'vue-owl-carousel'
import { getLanguage } from '@/utils/cookie'
import ArticleTpl from 'components/ArticleTpl'
import FunctionList from 'views/home/functionList'
import CustomCarousel from 'views/home/customCarousel'
import HomeFooterBanner from '@/layout/footer/footerBanner'

const tplData1 = {
  list: ['提供多行业精美模板免费使用', '支持首页可视DIY', '风格模板一键切换，无需重新上传资料'],
  imgSrc: ['images/home/1.png', 'images/home/2.png', 'images/home/3.png']
}
const tplData2 = {
  list: ['对接包括Google，Facebook，等主流社交网站', '可用视频、直播、传统网络广告等多种推广方式', '亿级美金的投放经验助您低成本高转化'],
  imgSrc: 'images/home/home1.png'
}
const tplData3 = {
  list: ['智能选品，热款、爆款、一网打尽', '专属智能物流高效出库，快速运输，畅行无阻', '千万量级热卖品类数据，助您一键开店，轻松运营  '],
  imgSrc: ['images/1.png', 'images/2.png', 'images/3.png', 'images/4.png', 'images/5.png']
}
const tplData4 = {
  list: ['多站点、多语言、多币种，灵活的结算方式', '后台交互和功能更符合中国卖家的使用习惯，提升运营效益', '丰富的营销工具营造火爆的购物氛围，提升购买便捷度'],
  imgSrc: 'images/home/home2.png'
}
const tplModel = ['images/home/model/tpl1.png', 'images/home/model/tpl2.png', 'images/home/model/tpl3.png', 'images/home/model/tpl4.png', 'images/home/model/tpl5.png', 'images/home/model/tpl6.png', 'images/home/model/tpl7.png', 'images/home/model/tpl8.png', 'images/home/model/tpl9.png', 'images/home/model/tpl10.png']
const tplModel1 = ['images/home/model/tpl1.png', 'images/home/model/tpl2.png', 'images/home/model/tpl3.png', 'images/home/model/tpl4.png', 'images/home/model/tpl5.png']
const tplModel2 = ['images/home/model/tpl6.png', 'images/home/model/tpl7.png', 'images/home/model/tpl8.png', 'images/home/model/tpl9.png', 'images/home/model/tpl10.png']
export default {
  name: 'Home',
  components: { HomeFooterBanner, CustomCarousel, FunctionList, ArticleTitle, ArticleTpl, carousel },
  data() {
    return {
      tplData1: tplData1,
      tplData2: tplData2,
      tplData3: tplData3,
      tplData4: tplData4,
      tplModel: tplModel,
      tplModel1: tplModel1,
      tplModel2: tplModel2,
      brandList: [],
      Option: {
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    enzh() {
      return getLanguage()
    }
  },
  created() {
    for (let i = 1; i <= 39; i++) {
      if (i === 27) {
        continue
      }
      this.brandList.push(require(`../../assets/img/brand/${i}.png`))
    }
  },
  methods: {
    handleServerBtn(index) {
      this.serverFlag = index
    },
    toLoginPage(index) {
      this.$router.push({ path: '/register', query: { registerType: 'login' }})
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-col{
  overflow: hidden;
  background-color:#00307D;
  height: 650px
}
@media only screen and (max-width: 992px) {
  .banner-col{
    height: unset;
  }
}
.home_text{
  text-align: left;
  div.title h2{
    font-size: 64px;
    color: #fff;

  }
  div.title h2 .org{
    color: #FFA200;
  }
  div.title p{
    color: white;
    font-size: 24px;
    margin-top: 31px;
  }
  background-color: #00307D;
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen  and (min-width: 0px)  and (max-width: 576px) {
  .home_text{
    text-align: left;
    div.title h2{
      font-size: 50px;
      color: #fff;

    }
    div.title h2 .org{
      color: #FFA200;
    }
    div.title p{
      color: white;
      font-size: 18px;
      margin-top: 31px;
    }
    background-color: #00307D;
    width: 100%;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.a_white{
  color: white !important;
  &:after{
    border-top-color: white !important;
    border-right-color: white !important;
  }
}
.title-word:before{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_left_white.png') no-repeat;
  margin-right: 10%;
  background-size: 100%;
}
.title-word:after{
  content: " ";
  display: inline-block;
  width: 111px;
  height: 8px;
  background: url('/images/title_right_white.png') no-repeat;
  margin-left: 10%;
  background-size: 100%;
}
.title-word{
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 3.66rem;
  font-weight: bolder;
  text-align: center;
  vertical-align: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.home-module{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 414px;
  background-image: url('/images/bg1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}
.modelImg{
  max-width: 251px;
  max-height: 348px;
  width: 100%;
}
::v-deep .swiper-wrapper{
  display: flex;
}
.recommendPage2 {
  position: relative;
  width: 100%;
}

.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 878px;
}

.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-wrapper{
  display: flex;
}

.title{
  font-size: $titleSize;
  font-weight: 500;
  color: #141414;
}
.brand{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 577px;
  & .title{
    font-size: $titleSize;
    color: #141414;
    margin-top: 84px;
    margin-bottom: 14px;
  }
  img{
    width: 229.54px;
    height: 123.6px;
  }
  .brand_list{
    display: flex;
  }
}

.brand-section {
  padding: 60px 0;
  background: #fff;
}
.brand-section .owl-stage-outer {
  padding: 20px 0;
}
.brand-section .item {
  background: #fff;
  border: 1px solid #F4F2F9;
  padding: 28px 0;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100px;
  transition: all .4s;
}
.brand-section .item img {
  width: 140px;
  transition: all .4s;
}
.brand-section .item:hover, .brand-section .item:focus {
  box-shadow: 0 10px 20px rgba(205, 196, 219, 0.3);
}
.brand-section .item:hover img, .brand-section .item:focus img {
  filter: grayscale(0);
}
.brand-section a {
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  color: #313131;
  text-decoration: underline;
  margin-top: 20px;
}
.brand-section a:hover, .brand-section a:focus {
  text-decoration: underline;
  color: #FF9C1A;
}
@media only screen and (min-width: 0px) and (max-width: 992px)  {
  .home_video{
    height: auto;width: auto;max-width: 100%;
    min-height: unset;
  }
  .title-word .pc{
    display: none;
  }
}
@media only screen and (min-width: 993px)  {
  .home_video{
    min-height:644px;height: auto;
  }
  .title-word .mobile{
    display: none;
  }
}
</style>

<!-- 处理图片 -->
<style lang="scss" scoped>
::v-deep .owl-prev{
  background-color: transparent;
  font-size: 22rem;
}
.owl-nav > .owl-next{
  width: 100px;
  height: 100px;
}
//  width:320px-479px
@media only screen and (min-width:320px) and (max-width:479px) {
  .try-img{
    margin-top: 25px;
    width: 300px !important;
    height: 100px !important;
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width:480px) and (max-width:575px) {
  .try-img{
    margin-top: 25px;
    width: 450px !important;
    height: 150px !important;
  }
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 26.1rem;
      background-position: 30% 50%;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (min-width: 375px) and (max-width: 720px){
  ::v-deep .imgDiv .pcImg{
    height: 70rem !important;
  }
}
//  width:576px-767px
@media only screen and (min-width:576px) and (max-width:767px) {
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 26.1rem;
      background-position: 30% 50%;
    }
  }
}
</style>

<style lang="scss" scoped>

@media only screen and (min-width:720px) and (max-width:768px) {
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 26.1rem;
      height: calc(100vh - 100px) !important;
      background-position: 30% 50%;
    }
  }
}

//  width:768px-991px
@media only screen and (min-width:768px) and (max-width:991px) {
  // banner
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 26.1rem;
      height: calc(100vh - 100px) !important;
    }
  }
}

// banner
@media (min-width: 960px) and (max-width:1280px) {
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 36rem;
      height: calc(100vh - 100px) !important;
    }
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 39.6rem;
      height: calc(100vh - 100px) !important;
    }
  }

}
@media (min-width: 1440px) and (max-width: 1700px){
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 43.632rem;
      height: calc(100vh - 100px) !important;
      background-position: 39%;
    }
  }
}

@media (min-width: 1700px) {
  ::v-deep .imgDiv{
    & .pcImg{
      // height: 47.3rem;
      height: calc(100vh - 100px) !important;
    }
  }
}
</style>
