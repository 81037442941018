/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description Article Temp
*/

<template>
  <div class="ArticleTpl">
    <a-row type="flex" justify="center" :class="{'reverse':reverse}">
      <a-col :xl="9" :lg="12" :span="22" style="display: flex;justify-content: center">
        <div class="tpl-text">
          <slot name="title" />
          <ul class="list">
            <li v-for="(item,index) in tplData.list" :key="index" class="listItem">
              {{ item }}
            </li>
          </ul>
          <slot name="ad" />
        </div>
      </a-col>
      <a-col :xl="9" :lg="12" :span="22">
        <img v-if="!Array.isArray(data.imgSrc)" class="tplImg" :src="tplData.imgSrc">
        <ul v-else class="flash" style="margin: 0">
          <li v-for="(item,index) in tplData.imgSrc" :key="'liImg' + index" :class="{'show': index === ulIndex}">
            <img :src="item" class="tplImg" >
          </li>
          <img class="tplImg" style="visibility: hidden" :src="tplData.imgSrc[0]">
          <img class="tplImg" style="position:absolute;left: 0;top: 0;z-index: -1" src="/images/bg.png" v-if="mapBg">
        </ul>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'ArticleTpl',
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
      // default: () => { return { list: [], imgSrc: '', title: '' } }
    },
    speed: {
      type: Number,
      default: 5000
    },
    mapBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tplData: {},
      ulIndex: 0,
      time: ''
    }
  },
  created() {
    if (Object.keys(this.data).length > 0) {
      this.tplData = this.data
    }
    if (Array.isArray(this.data.imgSrc)) {
      this.$nextTick(() => {
        this.loop()
      })
    }
  },
  methods: {
    loop() {
      this.time = setInterval(() => {
        if (this.ulIndex < this.data.imgSrc.length - 1) {
          this.ulIndex += 1
        } else {
          this.ulIndex = 0
        }
      }, this.speed)
    }
  },
  beforeDestroy() {
    clearInterval(this.time)
  }
}
</script>

<style lang="scss" scoped>
.flash{
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 720px;
  max-height: 510px;
}
.flash li {
  position: absolute;
  transition: all .5s ease-in-out;
  opacity: 0;
}
.flash li.show{
  opacity: 1;
}
.reverse{
  flex-direction: row-reverse !important;
}
.tplImg{
  max-width: 720px;
  max-height: 510px;
  width: 100%;
}
.tpl-text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: left;
}
ul{
  margin-top: 36px;
  font-size: 18px;
}
ul.list > li:before {
  content: '';
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FF4800;
  margin-right: 20px;
}
</style>
