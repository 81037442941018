<!-- home 底部宣传 banner -->
<template>
  <a-row type="flex" justify="center">
    <a-col :xl="18" :lg="18" :span="22">
      <section class="footer-banner" style="margin-bottom: -145px">
        <div class="home-module-word">
          <h3 style="font-size: 3.66rem;color: #fff;font-weight: 800;">
            免费试用Vllshop，立刻开始跨境电商
          </h3>
          <a class="tplAD a_white" @click="$register()">Try it now</a>
        </div>
      </section>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'HomeFooterBanner'
}
</script>

<style scoped lang="scss">
.a_white{
  color: white !important;
  &:after{
    border-top-color: white !important;
    border-right-color: white !important;
  }
}
.tplAD{
  color: #0364FF;
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 2rem;
}
.footer-banner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 285px;
  border-radius: 15px;
  background-image: url('/images/bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}

</style>
