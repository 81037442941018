<template>
  <section class="home-module home-banner" style="">
    <a-row type="flex" justify="center" class="pc">
      <a-col :xl="18" :lg="23" :span="23" style="position: relative;flex-wrap: nowrap">
        <a-row style="height: 100%;" type="flex" class="box">
          <a-col :md="9" :span="23" style="flex-wrap: nowrap">
            <img class="Custom_img" src="/images/home/custom1.png">
          </a-col>
          <a-col :md="15" :span="23" style="height: 100%" class="Custom_word">
            <img class="bg" src="/images/bg3.png">
            <div class="text_box" style="height: 213px">
              <p class="has_squeal">Vllshop“<span style="font-weight: 400">领航服务</span>”非常贴心<br>
                帮助我们团队快速上手独立站运营</p>
              <p class="text">在我们完全不了解如何建站的情况下，提供了网站搭建，<br>
                模版定制，第一批货品上架，等贴心服务。并且对我们的<br>
                网站运营提供了很多建议，实用且有效。让我们的网站快<br>
                速步入正轨</p>
              <!-- <p style="text-align: right;font-size: 1.7rem">@深圳市达人无限有限公司</p> -->
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  name: 'CustomCarousel'
}
</script>
<style lang="scss" scoped>
.Custom_word{
  max-width: 934px;
  max-height: 511px;
  margin-top: 80px;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  .bg{
    position: absolute;
    left: 23px;
    bottom: 23px;
  }
  p:nth-of-type(1){
    font-weight: 800;
    font-size: 3rem;
    text-align: left;
  }
  .text{
    margin-top: 43px;
    font-size: 16px;
    text-align: left;
    color: #68A4FF;
  }
  .text_box{
  }
}
.Custom_img{
  max-width: 623px;
  max-height: 623px;
  margin-left: 10px;
  position: relative;
  top: -90px;
  z-index: 1;
}
@media only screen and (min-width: 1440px) {
  .has_squeal:after{
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: #FF4800;
    border-radius: 50%;
    top: 10%;
    left: 10%;
    position: relative;
  }
  .has_squeal:before{
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: #FF4800;
    border-radius: 50%;
    top: -10%;
    left: -10% ;
    position: relative;
  }
}
@media  only screen and (min-width:0px) and (max-width:767px) {
  .Custom_word{
    padding: 0;
    justify-content: center;
  }
  .has_squeal:before{
    display: none;
  }
  .has_squeal:after{
    display: none;
  }
  box{
    flex-wrap: wrap !important;
  }
  .Custom_img{
    width: 100%;
  }
  .Custom_word{
    margin-top: 0;
    height: 400px;
    position: relative;
    top: -200px;
    left: 10px;
    box-shadow:0px 15px 22px -9px  rgba(0,0,0,0.5); border-radius:10px;
  }
  .home-module{
    min-height: unset;
    height: 80%;
  }
}
</style>
<style scoped lang="scss">
.home-module{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 718px;
  background-image: url('/images/bg1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
  margin-top: 70px;
  min-height: 600px;
}
</style>
