<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col v-for="(item,index) in list" :key="'funlist1' + index" :xl="6" :lg="6" :span="24">
        <div class="fun-text">
          <img :src="item.img">
          <div class="fun_title">
            <em>{{ item.em }}</em>{{ item.title }}
          </div>
          <ul class="list">
            <li v-for="(text,index) in item.ulList" :key="'funlistUl1' + index" class="listItem">
              {{ text }}
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" style="margin-top: 130px">
      <a-col v-for="(item,index) in list2" :key="'funlist2' + index" :xl="6" :lg="6" :span="24">
        <div class="fun-text">
          <img :src="item.img">
          <div class="fun_title">
            <em>{{ item.em }}</em>{{ item.title }}
          </div>
          <ul class="list">
            <li v-for="(text,index) in item.ulList" :key="'funlistUl2' + index" class="listItem">
              {{ text }}
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const list = [
  {
    img: '/images/home/funlist1.png',
    ulList: ['简单注册，5步快速建站', 'SAAS板块无需安装部署服务器'],
    em: '01',
    title: '智慧建站'
  },
  {
    img: '/images/home/funlist2.png',
    ulList: ['国内外大型自媒体战略合作', '营销流量扶持，打造KOL明星店铺'],
    em: '02',
    title: '大型直播流量扶持'
  },
  {
    img: '/images/home/funlist3.png',
    ulList: ['配备专业销售经理细致培训护航', '帮助用户快速建站实现运营闭环'],
    em: '03',
    title: '卖家扶持计划'
  }
]
const list2 = [
  {
    img: '/images/home/funlist4.png',
    ulList: ['针对型二级分销插件急速拓客引流', '不同领域流量一样收入囊中'],
    em: '04',
    title: '二级分销'
  },
  {
    img: '/images/home/funlist5.png',
    ulList: ['实时更新、分类消费者数据记录奇点', '快速直观的观测营销活动效果，先行一步'],
    em: '05',
    title: '数据分析'
  },
  {
    img: '/images/home/funlist6.png',
    ulList: ['Vllshop任务佣金式营销推广平台', '定制营销推广任务链式反应效果看得见'],
    em: '06',
    title: '定制推广营销服务'
  }
]
export default {
  name: 'FunctionList',
  data() {
    return {
      list: list,
      list2: list2
    }
  }
}
</script>

<style scoped>
em{
  font-size: 48px;
  color: #68A4FF;
}
img{
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  margin: 0 auto;
}
.fun_title{
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin: 30px 0;
}
.fun-text{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: left;
}
ul{
  font-size: 18px;
}
ul.list > li:before {
  content: '';
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FF4800;
  margin-right: 20px;
}
</style>
